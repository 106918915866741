import React from 'react';
import './InfoBox.css';
import { Contract } from '../home/style';

const InfoBox = () => {
  return (
    <div className='infoBox'>
        <h1 className='h2'><i>🪙MYC Tokenomics</i></h1>
      <strong className="strong">
        ☣️ EXPOSE (🦎) <i style={{color:'#fff'}}> BANKERS 🏦</i> <i style={{color:'#fff'}}>ELITES</i> WITH OUR TIER <i style={{color:'#fff'}}> 1️⃣ NFT'S!</i>
      </strong>
      {/* <br />
      <strong className="text-white text-lg font-semibold text-center mb-2">
        <i style={{color:'rgb(15, 255, 80)'}}>✅️ 17B</i> ACCREDITED
      </strong> */}
      <strong className=''> <i style={{color:'#fff'}}>✅️30B</i> LP SUPPLIED-LOCKED <i>(33YEARS)</i>
      </strong>
      <br />
      <strong className="">
        <i style={{color:'#fff'}}>🟢100B</i> TOKEN SUPPLY
      </strong>
      <br />
      <strong className="">
        <i style={{color:'#fff'}}>🔥51%</i> TOKENS <i style={{color:'#fff'}}>BURNED 🔥</i>
      </strong>
      <br />
      <strong className="">
        <i style={{color:'#fff'}}>✅️1.7B</i> RESERVE <i style={{color:'#fff'}}> MARKETING</i> <i style={{color:'#fff'}}>LISTING'S </i>
         & <i style={{color:'#fff'}}>DEVELOPMENT</i> ONLY 🧑‍💻 
      </strong>
      <br />
      <strong className="">
        ⚙️<i style={{color:'#fff'}}>Engage</i> with the <i style={{color:'#fff'}}>MYC</i> Ecosystem,
      </strong>
      <br />
      <strong className="">
          <i style={{color:'#fff'}}>NFTS</i> & access to applications 📱 available on <i style={{color:'#fff'}}>Apple</i> & <i style={{color:'#fff'}}>Google</i> Stores.
      </strong>
        <div className="address">
            <Contract onClick={() => window.open('https://etherscan.io/address/0xC7AEd118AE9B95DDaf0972C5F7012F21754e8c95', '_blank')}>
                  <span style={{ color: "#fff" }}>0xC7AEd...8c95 /</span>
                  <span style={{ color: "rgb(15, 255, 80)" }}><i>📝MYC NFT CONTRACT</i></span>
            </Contract>
            <Contract onClick={() => window.open('https://etherscan.io/address/0xd939212f16560447ed82ce46ca40a63db62419b5', '_blank')}>
                  <span style={{ color: '#fff' }}>0xD9392...19B5 /</span>
                  <span style={{ color: 'rgb(15, 255, 80)' }}><i>📝MYC TOKEN CONTRACT</i></span>
            </Contract>
          </div>

          <div className='infoBox mt-6'>
            <h1 className='h2'><i>💬Team Email:</i></h1>
          <strong className="">
            📩 support@mycult.io
          </strong>
        </div>
    </div>
      
  );
};

export default InfoBox;
