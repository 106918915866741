import styled from 'styled-components';
import angle from "../../assets/images/assets/angle.svg";


export const Container = styled.div`
    margin 0 auto;
    padding: 0 15px;
    background: transparent;
    font-family: 'Squartiqa 4F', sans-serif;
`;
export const Logo = styled.div`
    display: flex;
    align-items: center;

    a {
        display: flex;   
        align-items: center;

        .img-content {
            margin-left: 15px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;

            img {    
                width: 120px; // Normal size
                height: 120px; 
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        a .img-content img {
            width: 100px;
            height: 100px;
            margin-bottom: 20px;
        }
    }
`;



export const Header = styled.header`
    background-color: rgba(22, 21, 21, 0.5);
    padding:20px 0;
    width: 100%;
    top: 0;
    z-index: 10;      
`;

export const HeaderContent = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;    
    > div{
        display:flex;
        align-items: flex-start;
    }
    .fa {
        display: none;
        @media only screen and (max-width: 768px) {
          display: block;
          margin: 20px;
          cursor: pointer;     
        }
    }
    .header-btn {
        background: black;
        color: rgb(15, 255, 80);
        text-align: center;
        flex-wrap: nowrap;
        align-items: center;
        border: solid 1.5px white;
        border-radius: 10px;
        font-size: 15px;
        line-height: 20px;
        padding: 10px;
        width: 150px;
        p {
            margin: 0px;
            font-size: 15px;
        }
    }
    .address-container {
        display: flex;
        text-align: center;
        flex-wrap: nowrap;
        align-items: center;
        border-radius: 10px;
        color: #fff;
        font-size: 15px;
        line-height: 20px;
        padding: 10px;
        background: transparent;
        p {
            margin: 0px;
            font-size: 15px;
        }
    }    
    i.icon-angle {
        margin-left: 10px;
        background-image: url(${angle});
        height: 6px;
        width: 9px;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
    }

    @media only screen and (max-width: 768px) {
        flex-direction: column; // Stack elements vertically
        justify-content: center; // Center elements vertically
        align-items: center; // Center elements horizontally
        text-align: center; // Center text
    }
`;
export const ConnectWrapper = styled.div`
    flex-direction: column;
    font-weight: 600;
    color: #fff;
    padding: 0px;
    font-size: 15px;
    cursor: pointer;
    div div div{
        &:hover {
            color:#E3256A;
        }
    }  
    &:hover {
        color:#9ED104;
    }
    @media only screen and (max-width: 768px) {
        margin-left: 20px;
    }        
`;
export const HeaderCenter = styled.div`
    position: relative;    
`;
export const ButtonContainer = styled.div`
    display:flex;
    align-items:center;
    padding-right:20px;
    position:relative;
    img{
        width: 40px;
        height: 40px;
        border-radius: 20px;
    }
`;
export const DropDownMenus = styled.div`
    font-weight: 600;
   
    background: black;
    width: 150px;
    position: absolute;
    border: 1.5px solid white;
    border-radius: 25px;
    bottom: -50px;
    left: 0px;
    box-shadow: rgb(68 68 68) 0px 0px 5px;
    border-radius: 2px;
    z-index: 99;    
`;
export const DropDownMenu = styled.div`
    padding: 10px 15px;
    color: rgb(15, 255, 80);
    cursor: pointer;
    &:hover {
        background-color: transparent;
    }
`;

export const SocialIcons = styled.div`
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    a img {
        margin-left: 10px;
        width: 50px;
        height: 50px;
    }

    @media only screen and (max-width: 768px) {
        margin-top: 40px; // Adjusted spacing for mobile
        
        a img {
            width: 40px; // Adjust to desired mobile size
            height: 40px;
    }
`;
