import styled from 'styled-components';

export const Container = styled.div`
    background: transparent;
    margin: 0 auto;
    align-items: stretch;   
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }

     .infobox-container {
        max-width: 400px;
        width: calc(100% - 30px); 
        margin-bottom: 20px;
    }

    .things {
        display: flex;
        background-color: rgba(22, 21, 21, 0.5);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        justify-content: center;
        flex-direction: column;
        max-width: 400px;
        border-radius: 15px;
        width: calc(100% - 30px); 
        margin-bottom: 20px;
        padding: 20px;

        img {
            width: 100%; // Ensure image takes full width
            border-bottom: 2px solid white;
            border-radius: 15px 15px 0px 0px; // Round the top corners of the image
        }
        
        .content-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            padding: 10px 0;
        
            .left, .right {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: 18px;
                color: #fff;
                font-weight: 300;
                font-family: 'Squartiqa 4F', sans-serif;
                margin: 0;
            }
        }
        
        .count {
            display: flex;
            align-items: center;
            justify-content: center; /* Ensure content is centered */
            margin-top: 10px;
            margin-bottom: 20px;
            margin-left: auto; /* Ensure left and right margins are automatic */
            margin-right: auto;
            width: 75%; /* Adjust to your preference */
            
            input {
                width: 75%;
                border: 1px solid transparent;
                height: 43px;
                font-size: 20px;
                text-align: center;
                background-color: rgba(50, 50, 50, 0.5);
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                color: #fff;
                font-weight: 300;
                border-radius: 5px;
                font-family: 'Squartiqa 4F', sans-serif;
            }
    
            button {
                color: #fff;
                width: 45px;
                border: 1px solid;
                cursor: pointer;
                height: 45px;
                font-size: 24px;
                background-color: rgba(50, 50, 50, 0.5);
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                transition: all 0.3s ease;
                border-radius: 5px;
            }
        }
        
        .mint {
            background-color: rgba(50, 50, 50, 0.5);
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            padding: 10px;
            border-radius: 10px;
            border: solid 2px white;
            cursor: pointer;
            text-align: center;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            font-family: 'Squartiqa 4F', sans-serif;
            animation: flash 2s infinite; /* 1s duration, infinite repeat */
        }
    }  

`;

export const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1000px;
    max-width: 90%;
    margin: auto;
    margin-top: 0px;
    position: relative;
    top: 50%;
    transform: translateY(-30%);

    @media (max-width: 768px) {
        margin-top: 70px;  // or any suitable value to push it down
        // Adjust other properties if necessary
    }
`;

export const Contract = styled.div`
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;

    span {
        font-size: 1.5rem;
        font-weight: bold;
    }
`;




