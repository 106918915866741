import React, { useState, useEffect } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { useConnect } from 'wagmi'
import { Toaster } from 'react-hot-toast';

import "./App.css";
import "antd/dist/antd.css";


import ConnectDialog from "./components/ConnectDialog";
import NetworkErrorDialog from './components/NetworkErrorDialog';
import Home from "./pages/home/home";
import { connectors } from "./utils/connectors";
import { useEagerConnect } from "./hooks/useEagerConnect";
import { connectorLocalStorageKey } from "./utils/connectors";

function App() {

  const [connectModalOpen, setConnectModalOpen] = useState(false);
  const connectAccount = () => {
    setConnectModalOpen(true);
  }

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [networkError, setNetworkError] = useState(null);


  const [connectError, setConnectError] = useState(null);
  const { connect } = useConnect({
    onError(error) {
      console.log('useConnect Error :', error.message);
      setConnectError(error.message);
      window.localStorage.setItem(connectorLocalStorageKey, "");
    },
    onSuccess(data) {
      console.log('useConnect Success:');
      setConnectError(null);
    },
  });

  const connectToProvider = (connector) => {
    connect({ connector });
  }

  // mount only once
  const [triedEager, triedEagerError] = useEagerConnect();

  useEffect(() => {
    if (triedEagerError) {
      setNetworkError(triedEagerError);
      setErrorModalOpen(true);
    } else if (connectError) {
      setNetworkError(connectError);
      setErrorModalOpen(true);
    } else {
      setNetworkError(null);
      setErrorModalOpen(false);
    }

  }, [triedEagerError, connectError])

  const closeErrorModal = () => {
    setNetworkError(null);
    setErrorModalOpen(false);    
  }

  return (
    <div className="App">
      <Toaster
        position="top-center"
        toastOptions={{
          success: { duration: 3000 },
          error: { duration: 3000 },
        }}
      />
      <Router>
        <Switch>
          <Route path="/" exact render={(props) => (<Home {...props} connectAccount={connectAccount} />)} />
        </Switch>
      </Router>

      <ConnectDialog
        open={connectModalOpen}
        handleClose={(event, reason) => {
          setConnectModalOpen(false)
          if (reason === "backdropClick") {
            return false;
          }
          if (reason === "escapeKeyDown") {
            return false;
          }
        }}
        connectors={connectors}
        connectToProvider={connectToProvider}
      />

      <NetworkErrorDialog
        open={!!errorModalOpen}
        onClose={(event, reason) => {
          setErrorModalOpen(false)
          if (reason === "backdropClick") {
            return false;
          }
          if (reason === "escapeKeyDown") {
            return false;
          }
        }}
        handleClose={closeErrorModal}
        message={networkError}
      />
    </div>
  );
}

export default App;
