import React from 'react';
import '../pages/InfoBox/InfoBox.css';

const Uni = () => {
  return (
    <div className='uni'>
        {/* <h1 className='h1'><i>🏪UniSwap</i></h1> */}
        <div>
          <iframe src="https://app.uniswap.org/tokens/ethereum/0xd939212f16560447ed82ce46ca40a63db62419b5" width="600" height="600"></iframe>
        </div>   
    </div>
  );
};

export default Uni;
