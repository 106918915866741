import React from 'react';
import '../pages/InfoBox/InfoBox.css';

const Chart = () => {
  return (
    <div className='chart'>  
        <div>
            <iframe className='chart2' id="geckoterminal-embed" title="GeckoTerminal Embed" src="https://www.geckoterminal.com/eth/pools/0xf6e14072490f5882b04d9dd5bc15dbb97133a38e?embed=1&info=1&swaps=0" frameborder="0" allow="clipboard-write" allowfullscreen></iframe>
        </div>
    </div>
  );
};

export default Chart;
