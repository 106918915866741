import styled from 'styled-components';

export const Paper = styled.div`
  outline: none !important;    
  background-color: white;
  max-width: 300px;
  position: relative;
  width: 95vw;
  border-radius: 7px;
  &:hover {
    box-shadow: 0 0 5px #fff;
    .top-left {
      width: 4rem;
      height: 3rem;
    }
    .bottom-right {
      width: 4rem;
      height: 3rem;
    }
    .middle-border {
      width: 5rem;
    }
  }  
`
export const ModalContents = styled.div`
  padding: 2rem;
  p {
    font-size: 16px;
    font-weight: 400;
    max-width: 600px;
    margin: 0 0 20px;
    color: #777;
    line-height: 1.6;
  }
`
export const Button = styled.button`
  background-color: var(--colorOrange);
  color: white;
  border: 0;
  padding: 12px 30px;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: '15px';
  line-height: 1.3;
  letter-spacing: 0.025em;
  font-family: 'Roboto', sans-serif;
  height: 'auto';
  width: 'auto';
  margin: '0';
  &:hover {
    background-color: #333;
    color: white;
  }
`