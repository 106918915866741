/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { custom_network } from "../utils";
import { connectorLocalStorageKey, getConnector } from "../utils/connectors";
import { useConnect, useNetwork, useSwitchNetwork, useAccount  } from 'wagmi';

export function useEagerConnect() {
  const [tried, setTried] = useState(false);
  const [eagerError, setEagerError] = useState(null);  

  const { connect, error, isLoading } = useConnect();
  useEffect(() => {
    if (error && !isLoading) {
      console.log('error:', error);
      window.localStorage.setItem(connectorLocalStorageKey, "");
    }    
  }, [error, isLoading])

  
  const { chain } = useNetwork();
  const { isConnected } = useAccount();
  const { switchNetwork } = useSwitchNetwork({
    onError(error) {
      console.log('switchNetwork error :', error.message);
      setEagerError(error.message);
    },
    onSuccess(data) {
      console.log('switchNetwork Success:');
      setEagerError(null);
    },
  })


  useEffect(() => {
    const connector = window.localStorage.getItem(connectorLocalStorageKey);
    if (connector && connector !== "") {
      const currentConnector = getConnector(connector);      
      if (isConnected) {        
        if (chain && (chain.id !== custom_network.id)) {
          setEagerError("You're connected to invalid network. Please switch the network.");
          switchNetwork?.(custom_network.id);          
        }        
      } else {        
        connect({ connector: currentConnector });              
        setTried(true);
      }      
    }    
  }, [connect, chain, isConnected]) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && isConnected) {
      setTried(true);
    }
  }, [tried, isConnected])

  return [tried, eagerError]
}
