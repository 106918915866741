import React, { useState, useEffect } from 'react';
import { fetchBalance, fetchSigner } from '@wagmi/core';
import { useAccount, useNetwork } from 'wagmi'
import { ethers } from "ethers";
import toast from "react-hot-toast";
import { custom_network } from '../../utils';
import InfoBox from '../InfoBox/InfoBox.js';
import { MainContent } from './style';
import "./home.css";
import Bio from "../Bio/Bio";


import Header from '../header/header';
import * as Element from "./style";

import swardImg from "../../assets/images/assets/img-sward.gif";
import { getMintInfo, mint } from '../../utils/contracts';
import Uni from '../../components/UniSwap';
import Chart from '../../components/Chart';
// import BoatGame from '../../components/Game/BoatGame';

function Home(props) {

   const { chain } = useNetwork();
   const { address, isConnected, connector: activeConnector } = useAccount();   
   
   const [balance, setBalance] = useState(0);
   useEffect(() => {
      if (address && isConnected && chain && (chain.id === custom_network.id)) {
         fetchBalance({
            address: address,
          })
            .then((result) => {
               console.log('balance result:', result);
               var etherVal = parseFloat(ethers.utils.formatUnits(String(result.value), result.decimals));
               setBalance(etherVal)
            })
            .catch((error) => {
               console.log('balance fetch error:', error);
               setBalance(0)
            })
      }
   }, [address, isConnected, chain])

   const [mintInfo, setMintInfo] = useState({
      price: 0,
      totalSupply: 0,
      maxSupply: 0
   });

   useEffect(() => {
      getMintInfo()
         .then((result) => {
            setMintInfo(result)
         })
         .catch(() => {
            setMintInfo({
               price: 0,
               totalSupply: 0,
               maxSupply: 0
            })
         })
   }, [])

   const [mintCount, setMintCount] = useState(1);
   const decreaseHandle = () => {
      if (mintCount > 1) {
         setMintCount(mintCount - 1);
      }
   };
   const increaseHandle = () => {
      if (mintCount < 10) {
         setMintCount(mintCount + 1);
      }
   };


   async function mintNow() {
      if (address && isConnected && chain && (chain.id === custom_network.id)) {
         if (mintCount < 1) {
            toast.error("Please input count!");
            return;
         }
   
         if (balance < mintInfo.price * mintCount) {
            toast.error("Insufficiant funds!");
            return;
         }
   
         const load_toast_id = toast.loading("Minting MYC NFT...");
         const signer = await fetchSigner();
         mint(
            mintInfo.price,
            mintCount,
            signer
         ).then(async (result) => {
            toast.dismiss(load_toast_id);
            if (result) {
               toast.success("Mint Success!");
               window.location.reload();
               return;
            } else {
               toast.error("Failed to Mint");
               return;
            }
         }).catch(err => {
            console.log('error:', err);
            toast.dismiss(load_toast_id);
            toast.error("Failed Transaction");
            return;
         });
      }
   }

   return (
      <div>
          <div>
                <Header {...props} />
                <MainContent>
                    <h1 className="h1"><i></i><i></i></h1>
                </MainContent>
            </div>
         <Element.Container>
         <div className='infobox-container'>
            <Bio />
         </div>
            <div className='things'>
            <h1 className='h3'><i>🎭MYC Mint Sold Out!</i></h1>
               <img autoPlay muted loop src={swardImg} alt='MYC NFT' />
               <div className='content-row'>
                  <p className='left'>
                     Price:
                  </p>
                  <p className='right'>
                     {mintInfo?.price} ETH
                  </p>
               </div>
               <div className='content-row'>
                  <p className='left'>
                     Total Supply:
                  </p>
                  <p className='right'>
                     {mintInfo?.totalSupply}/{mintInfo?.maxSupply}
                  </p>
               </div>
               {
                  isConnected && chain && (chain.id === custom_network.id) &&
                  <div>
                     <div className='content-row'>
                        {/* <p className='left'>
                           You will pay
                        </p> */}
                        {/* <p className='right'>
                           {mintInfo?.price * mintCount} ETH
                        </p> */}
                     </div>
                     {/* <span className="count">
                        <button onClick={decreaseHandle}>-</button>
                        <input type="number" value={mintCount} disabled={true} onChange={e => setMintCount(parseFloat(e.target.value))} />
                        <button onClick={increaseHandle}>+</button>
                     </span> */}
                     {/* <div className='mint' onClick={() => mintNow()}>
                        Mint Now
                     </div> */}
                  </div>
               }

            </div>
         </Element.Container>
         <br />
         {/* <BoatGame /> */}
         <h1 className='h1'><i>UniSwap</i></h1>
            <Uni />
            <h1 className='h1'><i>MYC Chart</i></h1>
            <Chart />
            <InfoBox />
            <div className='info'>
            <h1 className='h2'><i>⚠️Disclaimers and Disclosures:</i></h1>
               <i className="disclaimer">Crypto currencies can be volatile and can result in losses. MYC token is a utilty token and should not be used as an investment tool. 
                  MYC tokens are not representations or stake percentage of a company or MYC NETWORK. MYC tokens are a medium of exchange for products and services within MYC Ecosytem. 
                  Nothing on this site constitutes as financial advice or any other professional advice. Losses can and are likely to occur and due to this, MYC NETWORK does not compensate for losses. 
                  By interacting with this contract you are consenting and accepting notice of this disclaimer and disclosure that you are interacting with this smart contract at your own risks.</i>

                  <ul className="custom-bullets">
                     <p>
                        MYC NETWORK LLC or its staff, founder, and or affiliates, participates, users of any kind interacting, transacting, buying, selling, utilizing, extracting liquidity, whether for business or personal use remain a
                        reality in this eco system. Users will casually buy or sell to complete transactions privately or publicly. Be aware there are and will always be users between all parties purchasing, selling, or utilizing our
                        tokens as means of payments for goods or services whereby selling tokens to extract liquidity to pay for those goods/services or adopting or accepting our currency as payment leads to extraction of liquidity,
                        As stated above MYC tokens are not an investment tool and often are in use of credits within our community as a currency. Turbulence in marketplace, loss of value or even 100% losses can occur due to a variety of forces
                        Our Founder and our community adopted this currency and want to ensure transparency in meaning, usecase, and probability of occurances are fully realized utilizing or interacting with our community utilty token MYC. We appreciate your support.
                     </p>
                  </ul>
            </div>
      </div>
   );
}

export default Home;

