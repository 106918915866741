import React from 'react';
import './Bio.css';

const Bio = () => {
  return (
    <div className='infoBox2'>

        <div className='h3'>
            <h1 className='h3'><i>☣️BIO</i></h1>
        </div>

        <h2>Truthers Problem, and Solution</h2>
        <p className='ptag'>
            BANNED, CENSORED, SUSPENDED from platforms like YouTube, Discord, Twitter our community went on 
            to build the most powerful military grade community communications application on Earth. MYC ODD APP!
        </p>

        <h2>Utility Side</h2>
        <p className='ptag'>
            MYC Token will be your Access credits! This unicorn emerging was built specifically for the crypto industry.
            Launched in 2023 we have accumulated just over 700 users with privacy security and encryption at the forefront
            of its use case. Our software protects users from data harvesting, screenshots, and data extraction.
            We tailored the application specifically for crypto communities who value their privacy as humans.
        </p>

        <h2>About MYC Community</h2>
        <p className='ptag'>
            At our core TRUTHER is our movement. We've been building for 4 years and along the way we have specialized 
            in exposing politicians, celebrities, bankers, non state actors, and state actors alike that only seek to 
            steal and hinder our freedoms. Together we pull together what the future may bring with the powers that be 
            so we can position ourselves safely. Truthers we encourage and welcome whether you are buying tokens or not, be with us.
        </p>

        <h2>NFT side:</h2>
        <p className='ptag'>
            We are launching NFT PFPs for Twitter use case. One thing we love to call the elite for the rituals they conduct 
            is Lizards or geckos since they can be a bit scaly! A total of 333 will be released with ultra rares within the collection!
        </p>
      
    </div>
  );
};

export default Bio;
