/* eslint-disable no-redeclare */
import { ethers } from "ethers";
import { getMintContract } from ".";

export function isAddress(address) {
    try {
        ethers.utils.getAddress(address);
    } catch (e) { return false; }
    return true;
}

export async function resolveName(name) {
    try {
        const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC);
        var address = await provider.resolveName(name);
        return address;
    } catch (e) { return ''; }    
}

export function toEth(amount, decimal) {
    return ethers.utils.formatUnits(String(amount), decimal);
}

export function toWei(amount, decimal) {
    return ethers.utils.parseUnits(String(amount), decimal);
}


/**
 * collection information
 */

export async function getMintInfo() {
    const contract = getMintContract(null);
    if (!contract) return null;
    try {
        const price = await contract._nftPrice();
        const maxSupply = await contract._maxSupply();
        const totalSupply = await contract.totalSupply();

        return {
            price: toEth(price, 18),
            maxSupply: Number(maxSupply),
            totalSupply: Number(totalSupply)
        }
    } catch (e) {
        console.log(e)
        return null;
    }
}


/**
 * mint
*/
export async function mint(price, amount, provider) {
    const contract = getMintContract(provider);
    if (!contract) return false;

    try {
        const tx = await contract.mint(amount, {
            value: toWei(amount*price,18)
        });
        await tx.wait(2);
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}