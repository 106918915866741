import { Contract } from '@ethersproject/contracts';
import { ethers } from "ethers";

import NFTCollectionABI from '../contracts/NFTCollection.json';


export const custom_network = {
  id: Number(process.env.REACT_APP_CHAINID),
  name: process.env.REACT_APP_CHAINNAME,
  network: process.env.REACT_APP_CHAINNETWORK,
  nativeCurrency: {
    decimals: 18,
    name: process.env.REACT_APP_CHAIN_CURRENCY_NAME,
    symbol: process.env.REACT_APP_CHAIN_CURRENCY_SYMBOL,
  },
  rpcUrls: {
    public: { http: [process.env.REACT_APP_RPC] },
    default: { http: [process.env.REACT_APP_RPC] },
  },
  blockExplorers: {
    etherscan: { name: 'Etherscan', url: process.env.REACT_APP_EXPLORE },
    default: { name: 'Etherscan', url: process.env.REACT_APP_EXPLORE },
  },
  contracts: {
    multicall3: {
      address: '0xC7AEd118AE9B95DDaf0972C5F7012F21754e8c95',
      blockCreated: 18382208,
    },
  },
}


export function getMintContract(provider) {
  if (!!provider) {
    return new Contract(process.env.REACT_APP_CONTRACT, NFTCollectionABI, provider);
  } else {   
    const rpcProvider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC);
    return new Contract(process.env.REACT_APP_CONTRACT, NFTCollectionABI, rpcProvider);
  }
}

export const shorter = (str) =>
  str?.length > 8 ? str.slice(0, 4) + '...' + str.slice(-4) : str